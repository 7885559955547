import React from 'react';
import { Image } from '@alterpage/gatsby-plugin-image';

import { container, imgBox, ratio, signature } from './offer-client-opinion-card.module.scss';
import { IClientOpinion } from '../../models/client-opinion.model';

interface IOfferClientOpinionCardProps {
    className?: string;
    client: IClientOpinion;
}

const OfferClientOpinionCard: React.FC<IOfferClientOpinionCardProps> = ({
    className = '',
    client,
}) => {
    const { media, content, name } = client;

    return (
        <div className={`${container} ${className}`}>
            <Image className={imgBox} media={media} ratioClass={ratio} objectFit="contain" />
            <p>{content}</p>
            <p className={signature}>{name}</p>
        </div>
    );
};

export default OfferClientOpinionCard;
