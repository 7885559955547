import React from 'react';

import { list } from './section-faq.module.scss';
import { ITitleProps } from '../atoms/title';
import { IFaq } from '../../models/faq.model';
import { getLessImportantHeading } from '../../utils/get-less-important-heading';

import Section, { ISectionProps } from '../hoc/section';
import SingleFaq from '../molecules/single-faq';

interface ISectionFaqProps {
    className?: string;
    titleProps?: ITitleProps;
    theme?: ISectionProps['theme'];
    faqs: IFaq[];
}

const SectionFaq: React.FC<ISectionFaqProps> = ({
    className = '',
    titleProps,
    theme = 'dark',
    faqs,
}) => {
    const QuestionTag = (titleProps?.Tag && getLessImportantHeading(titleProps.Tag)) || 'h2';

    return (
        <Section
            className={className}
            theme={theme}
            circleDisplay="none"
            contentDisplay="center"
            titleProps={titleProps}
            gridType="article"
            height="auto"
        >
            <ul className={list}>
                {faqs.map((faq) => {
                    return (
                        <li key={`faq-item-${faq.faqId}`}>
                            <SingleFaq singleFaq={faq} QuestionTag={QuestionTag} />
                        </li>
                    );
                })}
            </ul>
        </Section>
    );
};

export default SectionFaq;
