import React from 'react';

import { section, slider } from './section-offer-clients.module.scss';
import { breakpoints } from '../../config/breakpoints';
import { IClientOpinion } from '../../models/client-opinion.model';
import { ITitleProps } from '../atoms/title';

import Section from '../hoc/section';
import OfferClientOpinionCard from '../molecules/offer-client-opinion-card';
import Slider from '../hoc/slider';

interface ISectionOfferClientsProps {
    className?: string;
    clientOpinions: IClientOpinion[];
    titleProps?: ITitleProps;
}

const SectionOfferClients: React.FC<ISectionOfferClientsProps> = ({
    className = '',
    clientOpinions,
    titleProps,
}) => {
    if (clientOpinions.length === 0) return null;

    return (
        <Section
            className={`${className} ${section}`}
            theme="blue"
            contentDisplay="center"
            circleDisplay="none"
            height="auto"
            titleProps={titleProps}
        >
            <Slider
                className={slider}
                showArrows={true}
                spaceBetween={30}
                slidesPerView={1}
                autoplay={{ delay: 5000 }}
                autoHeight={false}
                rewind={true}
                centerInsufficientSlides={true}
                breakpoints={{
                    [breakpoints.laptop]: {
                        slidesPerView: 4,
                    },
                    [breakpoints.iPad]: {
                        slidesPerView: 3,
                    },
                    [breakpoints.tablet]: {
                        slidesPerView: 2,
                    },
                }}
            >
                {clientOpinions.map((clientOpinion) => {
                    return (
                        <OfferClientOpinionCard
                            key={`client-opinion-card-${clientOpinion.clientOpinionId}`}
                            client={clientOpinion}
                        />
                    );
                })}
            </Slider>
        </Section>
    );
};

export default SectionOfferClients;
