import React from 'react';
import { Link } from 'gatsby';
import { Image } from '@alterpage/gatsby-plugin-image';

import {
    container,
    imageBox,
    ratio,
    content,
    nameBox,
    leadText,
    clientText,
} from './project-realization-card.module.scss';
import { IProjectCard } from '../../models/project';
import { relations } from '../../config/relations';

interface IProjectRealizationCardProps {
    className?: string;
    project: IProjectCard;
    NameTag?: React.ElementType;
}

const ProjectRealizationCard: React.FC<IProjectRealizationCardProps> = ({
    className = '',
    project,
    NameTag = 'h2',
}) => {
    const { media, client, lead, pathname } = project;

    return (
        <Link className={`${container} ${className}`} to={pathname}>
            <Image
                className={imageBox}
                media={media}
                ratioClass={ratio}
                relation={relations.projectRealizationCover}
                fallback={relations.projectMockupDesktop}
            />
            <div className={content}>
                <NameTag className={nameBox}>
                    <span className={leadText}>{lead}</span>
                    <span className={clientText}>{client}</span>
                </NameTag>
            </div>
        </Link>
    );
};

export default ProjectRealizationCard;
