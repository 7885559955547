import React, { useState } from 'react';

import { list, card, hidden } from './section-project-realizations.module.scss';
import { IProjectCard } from '../../models/project';
import { ITitleProps } from '../atoms/title';
import { getLessImportantHeading } from '../../utils/get-less-important-heading';

import Section, { ISectionProps } from '../hoc/section';
import ProjectRealizationCard from '../molecules/project-realization-card';
import Button, { TButtonProps } from '../atoms/button';
import { useT } from '../../hooks/use-translation';

interface ISectionProjectRealizationsProps {
    className?: string;
    projects: IProjectCard[];
    titleProps?: ITitleProps;
    theme?: ISectionProps['theme'];
    projectsToShowCount?: number;
    buttonProps?: Pick<
        TButtonProps,
        'color' | 'isHoverTransparent' | 'hasArrow' | 'kind' | 'justify'
    >;
}

const SectionProjectRealizations: React.FC<ISectionProjectRealizationsProps> = ({
    className = '',
    titleProps,
    projects,
    theme,
    buttonProps,
    projectsToShowCount = 4,
}) => {
    const { t } = useT();
    const [visibleProjectsCount, setVisibleProjectsCount] = useState(projectsToShowCount);

    const NameTag = (titleProps?.Tag && getLessImportantHeading(titleProps.Tag)) || 'h2';

    const handleShowMore = () => {
        setVisibleProjectsCount((prev) => prev + projectsToShowCount);
    };

    return (
        <Section
            className={className}
            titleProps={titleProps}
            theme={theme}
            circleDisplay="none"
            contentDisplay="center"
            height="auto"
            gridType="wide"
        >
            <ul className={list}>
                {projects.map((project, index) => {
                    return (
                        <li
                            className={index >= visibleProjectsCount ? hidden : ''}
                            key={`project-realization-item-${project.projectId}`}
                        >
                            <ProjectRealizationCard
                                className={card}
                                project={project}
                                NameTag={NameTag}
                            />
                        </li>
                    );
                })}
            </ul>
            {visibleProjectsCount < projects.length && (
                <Button {...buttonProps} onClick={handleShowMore}>
                    {t('button.see')}
                </Button>
            )}
        </Section>
    );
};

export default SectionProjectRealizations;
