import React, { useCallback, useEffect, useRef, useState } from 'react';
import { graphql } from 'gatsby';
import { usePageContext } from '@alterpage/gatsby-plugin-alterpress-page-creator';

import { layout, articleBox, faq, modalThreshold, contact } from './offer.module.scss';
import { relations } from '../config/relations';
import { accentColors } from '../config/accent-colors';
import { IOffer } from '../models/offer.model';
import { useT } from '../hooks/use-translation';
import { useModalContext } from '../contexts/modal.context';

import MainLayout from '../layouts/main-layout';
import Hero from '../components/organisms/hero';
import FixedButton from '../components/atoms/fixed-button';
import SectionArticle from '../components/organisms/section-article';
import SectionFaq from '../components/organisms/section-faq';
import SectionProjectRealizations from '../components/organisms/section-project-realizations';
import SectionOfferClients from '../components/organisms/section-offer-clients';

interface IOfferPageProps {
    readonly data: {
        offer: IOffer;
    };
}

const OfferPage: React.FC<IOfferPageProps> = ({ data }) => {
    const { t } = useT();
    const { pathname } = usePageContext();
    const { addModal } = useModalContext();
    const { offer } = data;
    const {
        media,
        title,
        content,
        category,
        showModal,
        relatedFaqs,
        relatedProjects,
        relatedOpinions,
    } = offer;
    const thresholdRef = useRef<HTMLSpanElement | null>(null);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    const [showModalButton, setShowModalButton] = useState(getIsModalShown(pathname));

    const color = category?.color;
    const backgroundColor = accentColors[color || 'purple'];

    const handleCloseModal = () => {
        setShowModalButton(true);
    };

    const addSurveyModalCallback = useCallback(() => {
        addModal({
            modalKey: 'survey-modal',
            modalProps: {
                surveyType: showModal,
                onClose: handleCloseModal,
            },
        });
    }, [addModal, showModal]);

    useEffect(() => {
        const thresholdElement = thresholdRef.current;
        const isModalShown = getIsModalShown(pathname);

        if (!thresholdElement || !showModal || isModalShown) return;

        const handleObserver: IntersectionObserverCallback = (entries) => {
            entries.forEach((entry) => {
                if (!entry.isIntersecting) return;
                // Due to the operation of framer motion, when changing the page,
                // we add a timeout larger than the page transition animation.
                // Thanks to this, when you enter another page, we will not see a popup for that page.
                // Scrolling triggers the observer, and with the old template,
                // at the time of the transition, we are already on the new url.
                timeoutRef.current = setTimeout(() => {
                    addSurveyModalCallback();
                    sessionStorage.setItem(pathname, 'modalShown');
                    observer.unobserve(thresholdElement);
                }, 1000);
            });
        };

        const observer = new IntersectionObserver(handleObserver);
        observer.observe(thresholdElement);

        return () => {
            if (thresholdElement) {
                observer.unobserve(thresholdElement);
            }
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [addModal, addSurveyModalCallback, pathname, showModal]);

    return (
        <MainLayout
            className={layout}
            showContactForm={true}
            contactFormProps={{
                className: contact,
                titleProps: { children: t('contact.form.title') },
                description: t('offer.contact.form.description'),
                inputContentLabel: t('offer.contact.form.content.label'),
                buttonProps: {
                    kind: 'normalDark',
                    color: 'yellow',
                },
            }}
            showFloatingCircles={true}
        >
            {showModalButton && (
                <FixedButton onClick={addSurveyModalCallback}>
                    {t('offer.button.modal')}
                </FixedButton>
            )}
            <Hero
                titleProps={{ Tag: 'h1', children: title }}
                circleDisplay="image"
                imageProps={{ media }}
                imageMobileProps={{ media, relation: relations.mainImageMobile }}
                style={{ background: backgroundColor }}
            />
            <div className={articleBox}>
                <SectionArticle>{content}</SectionArticle>
                <span ref={thresholdRef} className={modalThreshold} />
            </div>
            {relatedFaqs && relatedFaqs.length > 0 && (
                <SectionFaq
                    className={faq}
                    faqs={relatedFaqs}
                    titleProps={{ Tag: 'h2', children: t('offer.faq.title') }}
                />
            )}
            {relatedOpinions && relatedOpinions.length > 0 && (
                <SectionOfferClients
                    titleProps={{
                        Tag: 'h2',
                        children: t('offer.opinions.title'),
                    }}
                    clientOpinions={relatedOpinions}
                />
            )}

            {relatedProjects && relatedProjects.length > 0 && (
                <SectionProjectRealizations
                    projects={relatedProjects}
                    titleProps={{
                        Tag: 'h2',
                        children: t('offer.project.realizations.title'),
                    }}
                    theme="light"
                    buttonProps={{
                        kind: 'normalDark',
                        color: 'yellow',
                        hasArrow: false,
                    }}
                />
            )}
        </MainLayout>
    );
};

function getIsModalShown(sessionKey: string): boolean {
    if (typeof window === 'undefined') return false;
    return !!sessionStorage.getItem(sessionKey);
}

export const query = graphql`
    query OfferPage($locale: String!, $offerId: Int!) {
        offer(locale: { eq: $locale }, offerId: { eq: $offerId }) {
            ...offerFields
        }
    }
`;

export default OfferPage;

export { Head } from '@alterpage/gatsby-plugin-alterpress-page-creator';
