import React, { useEffect, useState } from 'react';
import AnimateHeight, { Height } from 'react-animate-height';

import {
    container,
    open,
    header,
    questionText,
    button,
    icon,
    answerBox,
} from './single-faq.module.scss';
import { article, fullWidth } from '../../styles/article.module.scss';
import { IFaq } from '../../models/faq.model';

import Markdown from '../hoc/markdown';

interface ISingleFaqProps {
    className?: string;
    QuestionTag?: React.ElementType;
    singleFaq: IFaq;
    isOpen?: boolean;
}

const SingleFaq: React.FC<ISingleFaqProps> = ({
    className = '',
    singleFaq,
    QuestionTag = 'h3',
    isOpen = false,
}) => {
    const { faqId, question, answer } = singleFaq;
    const [height, setHeight] = useState<Height>(0);

    const handleToggleAnswer = () => {
        setHeight((prevHeight) => (prevHeight ? 0 : 'auto'));
    };

    useEffect(() => {
        setHeight(isOpen ? 'auto' : 0);
    }, [isOpen]);

    return (
        <div className={`${container} ${className} ${height ? open : ''}`}>
            <div className={header}>
                <QuestionTag className={questionText}>{question}</QuestionTag>
                <button
                    aria-expanded={!!height}
                    aria-controls={`faq-answer-${faqId}`}
                    className={button}
                    aria-label={height ? 'close' : 'expand'}
                    onClick={handleToggleAnswer}
                >
                    <span className={icon} />
                </button>
            </div>
            <AnimateHeight id={`faq-answer-${faqId}`} duration={500} height={height}>
                <div className={`${answerBox} ${article} ${fullWidth}`}>
                    <Markdown>{answer}</Markdown>
                </div>
            </AnimateHeight>
        </div>
    );
};

export default SingleFaq;
