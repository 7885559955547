import React from 'react';

import { newsletter, newsletterText, newsletterContent } from './section-article.module.scss';
import { article } from '../../styles/article.module.scss';
import { useT } from '../../hooks/use-translation';

import Markdown, { IMarkdownProps } from '../hoc/markdown';
import Section from '../hoc/section';
import SectionNewsletter from './section-newsletter';

interface ISectionArticleProps {
    className?: string;
    children?: IMarkdownProps['children'];
}

const SectionArticle: React.FC<ISectionArticleProps> = ({ className = '', children }) => {
    const { t } = useT();

    return (
        <Section
            className={className}
            theme="light"
            contentDisplay="left"
            circleDisplay="none"
            contentWidth="full"
            height="auto"
        >
            {children && (
                <div className={article}>
                    <Markdown
                        components={{
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            shortcode: () => {
                                return (
                                    <SectionNewsletter
                                        className={newsletter}
                                        theme="grey"
                                        inputLabel={t('article.newsletter.form.label.email')}
                                        titleProps={{
                                            Tag: 'h2',
                                            children: t('article.newsletter.title'),
                                        }}
                                        contentBoxClassName={newsletterContent}
                                        buttonProps={{ color: 'pink' }}
                                        errorColor="red"
                                    >
                                        <h3 className={newsletterText}>
                                            {t('article.newsletter.content')}
                                        </h3>
                                    </SectionNewsletter>
                                );
                            },
                        }}
                    >
                        {children}
                    </Markdown>
                </div>
            )}
        </Section>
    );
};

export default SectionArticle;
