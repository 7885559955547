import React, { useEffect, useState } from 'react';

import { button, collapsed, text, icon } from './fixed-button.module.scss';

import Cloud from '../../assets/images/svg/cloud.svg';

export interface IFixedButtonProps {
    className?: string;
    onClick?(): void;
    children?: React.ReactNode;
}

const FixedButton: React.FC<IFixedButtonProps> = ({ className = '', onClick, children }) => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseLeave = () => {
        setIsHovered(false);
        setIsCollapsed(true);
    };
    const handleMouseEnter = () => {
        setIsHovered(true);
        setIsCollapsed(false);
    };

    useEffect(() => {
        const handleScroll = () => {
            if (isHovered) return;
            setIsCollapsed(true);
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isHovered]);

    return (
        <button
            className={`${className} ${button} ${isCollapsed ? collapsed : ''}`}
            onClick={onClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <span className={text}>{children}</span>
            <Cloud className={icon} />
        </button>
    );
};

export default FixedButton;
